import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, useRoutes } from "react-router-dom";
import Share from './Share';

const theme = createTheme();

const Router = React.memo(() => {
  const element = useRoutes([
    {
      path: '/*',
      element: <Share />
    }
  ])
  return element
})

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </ThemeProvider >
  );
}

export default App;

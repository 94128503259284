import { useParams } from 'react-router'
import React from 'react'
import { Survezy } from 'survezy_widget'
import 'survezy_widget/dist/index.css'
import { useTheme } from '@emotion/react'
import { Box } from '@mui/material'

const Share = () => {
  const params = useParams()

  const theme = useTheme()

  return (
    <Box sx={{
      display: 'flex',
      height: '100vh',
      justifyContent: 'center'
    }}>
      <Survezy
        path={params?.['*']}
        sx={{
          [theme.breakpoints.up('md')]: {
            position: 'inherit',
            margin: 'auto',
            
          },
          [theme.breakpoints.down('md')]: {
            position: 'inherit',
            margin: 'auto',
            
          }
        }}
      />
    </Box>
  )
}

export default Share
